<div class="block-panel">
	<div class="dashboard_block {{panel.reason}}"
						[ngClass]="{'has-shadow': panel.hasShadow}"
						[attr.id]="panel.name">
		<ng-container *ngIf="panel.label">
			<div class="header" [class.header--tabs-container]="renderOrientation === 'TABS'">
				<!-- tabs -->
				<ng-container [ngSwitch]="renderOrientation">
					<ng-container *ngSwitchCase="'TABS'">
						<ng-container *ngFor="let headerPanel of panels">
							<div class="header-item truncate hover-pointer"
												[class.active]="panel.name === headerPanel.name"
												[matTooltipDisabled]="!headerPanel.isTruncated"
												matTooltip="{{headerPanel.label}}"
												(mouseover)="detectTruncatedField($event, headerPanel)"
												(click)="switchPanel(headerPanel)"
							>{{ headerPanel.label | titlecase }}
							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<!-- single panel -->
						<div class="truncate header-item"
											[matTooltipDisabled]="!panel.isTruncated"
											matTooltip="{{panel.label}}"
											(mouseover)="detectTruncatedField($event, panel)">{{ panel.label }}
						</div>
					</ng-container>
				</ng-container>
				<div class="flex flex-row header-button--row"
									*ngIf="panel?.meta?.infoIcons && panel?.meta?.infoIcons?.length > 0">
					<ng-container *ngFor="let iconBtn of panel?.meta?.infoIcons">
						<ng-container *ngIf="iconBtn.asButton">
							<button [matTooltip]="replaceSpaces(verifyTranslation(iconBtn.description))"
															matTooltipPosition="below"
															class="btn btn--icon-and-text btn--{{getButtonType(iconBtn.type)}} "
															(click)="metaButtonClicked(iconBtn, panel)">
								<i class="{{ getButtonIcon(iconBtn) }} panel-info-icon"
											[@loadingIconState]="iconBtn.loading"
											[ngClass]="{ 'mdi-loading mdi-spin': iconBtn.loading === 'loader' }">
								</i>{{ verifyTranslation(iconBtn.label) }}
							</button>
						</ng-container>
						<ng-container *ngIf="!iconBtn.asButton">
							<i [matTooltip]="replaceSpaces(iconBtn.description)"
										matTooltipPosition="below"
										class="{{ getIcon(iconBtn) }} ml-2 panel-info-icon "
										[@loadingIconState]="iconBtn.loading"
										[ngClass]="{ 'mdi-loading mdi-spin': iconBtn.loading === 'loader',
											   'hover-pointer': iconBtn.action,
											   'hover-help': (!iconBtn.action)
											   }"
										(click)="metaButtonClicked(iconBtn, panel)"></i>
						</ng-container>
					</ng-container>
					<ng-content select="[headerRight]"></ng-content>
				</div>
			</div>

			<!-- Render the filters here if hasTopFilter -->
			<div class="top-filter-row" *ngIf="true">
				<ng-container *ngTemplateOutlet="filterContent"></ng-container>
			</div>
		</ng-container>

		<div *ngIf="legendPanel" class="{{panel.class}} legend {{panel.reason}}">
			<!-- dynamic component resolving of the paneltype -->
			<div [csDashboardComponentLoader]="legendPanel.component"
								(componentInstantiated)="trackComponent($event, legendPanel)"
								[componentName]="legendPanel.name"
								[componentPanel]="legendPanel"
								[componentData]="legendPanel.data"
								[componentHeight]="legendPanel.height">
			</div>
		</div>
		<div class="content {{panel.class}} {{panel.reason}}"
							[@collapsePanel]="panel.isCollapsed"
							csIsSticky="is-horizontal-sticky"
							[class.with-footer]="panel.hasFooter">
			<ng-content select="[contentTop]"></ng-content>
			<!-- dynamic component resolving of the paneltype -->
			<div [csDashboardComponentLoader]="panel.component"
								(componentInstantiated)="trackComponent($event, panel)"
								(componentCleared)="untrackComponent($event)"
								[componentName]="panel.name"
								[componentPanel]="panel"
								[componentData]="panel.data"
								[componentHeight]="panel.height">
			</div>
		</div>
		<div class="panel-footer" *ngIf="panel.hasFooter">
			<div class="panel-footer-info-container flex flex-row">
				<!-- Render the filters in the footer -->
				<div class="footer-filter flex" *ngIf="panel?.options">
					<ng-container *ngTemplateOutlet="filterContent"></ng-container>
				</div>
				<ng-container *ngIf="panel.note">
					<div class="flex flex-row align-items-center panel-note-container">
						<i class="mdi mdi-information panel-note-icon"></i>
						<small class="f-body-s panel-note" [innerHTML]="panel.note">
						</small>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<!-- Filter Content Template -->
<ng-template #filterContent>
	<ng-container *ngFor="let optionList of panel?.options?.dataAnnotation?.fields">
		<div class="panel-option__container"
							[class.panel-option-readonly]="optionList?.readOnly"
							*ngIf="optionList.visible === true">
			<small class="panel-option__label f-body-s"
										*ngIf="optionList?.label"><b>{{ optionList?.label }}</b></small>
			<ng-container *ngIf="!optionList?.readOnly">
				<div class="flex flex-row align-items-center drop-filter">
					<i *ngIf="!optionList?.label"
								class="mdi mdi-filter-variant panel-filter-icon"></i>
					<div class="panel-filter-select">
						<button mat-button
														class="btn btn-default btn-sm dropdown-toggle"
														[matMenuTriggerFor]="menu"
						>{{ getCurrentValue(panel, optionList) }}
						</button>
						<mat-menu #menu="matMenu">
							<ng-container
								*ngFor="let lookup of getLookup(panel, optionList?.lookup)">
								<button mat-menu-item
																class="mat-menu-item-filter"
																(click)="panelOptionClicked(lookup.key, panel, optionList)">{{ lookup.value }}
								</button>
							</ng-container>
						</mat-menu>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="optionList?.readOnly">
				<small>{{ getCurrentValue(panel, optionList) }}</small>
			</ng-container>
		</div>
	</ng-container>
</ng-template>
