import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit, Optional,
	Output
}                                                        from '@angular/core';
import { collapse }                                      from '@cs/common/animations';
import { DashboardPanelMetaInfoEnum }                    from './models/dashboard-panel-meta-info';
import { RenderOrientation }                             from './models/render-orientation';
import { DashboardDownloadButtonEventArgs }              from './models/dashboard-download-button-event-args';
import { DashboardGridColumn }                           from './models/dashboard-grid-column';
import { DashboardGridData }                             from './models/dashboard-grid-data';
import { DashboardGridLayout }                           from './models/dashboard-grid-layout';
import { DashboardGridRow }                              from './models/dashboard-grid-row';
import { DashboardPanel }                                from './models/dashboard-panel';
import { DashboardPanelInfoIcon }                        from './models/dashboard-panel-info-icon';
import { DashboardPanelSettingEventArgs }                from './models/dashboard-panel-setting-event-args';
import { DashboardPanelType }                            from './models/dashboard-panel-type';
import { IDashboardComponent }                           from './models/i-dashboard-component';
import { IDashboardPanel }                               from './models/i-dashboard-panel';
import { NotifyServerForChangesDashboardPanelEventArgs } from './models/notify-server-for-changes-dashboard-panel-event-args';
import { PanelSettings }                                 from './models/panel-settings';

import {
	DashboardChartComponent,
	DashboardChartNxtComponent,
	DashboardCombiEntryStateComponent,
	DashboardEmptyComponent,
	DashboardGaugeComponent,
	DashboardGenericTableComponent,
	DashboardHtmlComponent,
	DashboardIndicatorsComponent,
	DashboardInformationComponent,
	DashboardSingleIndicatorsComponent,
	DashboardStatisticsComponent,
	DashboardTableComponent,
	HyperlinkListComponent,
	DashboardListGroupedItemsComponent,
	DashboardTasksComponent,
	DashboardUpdatesComponent,
	DashboardChartNxtSliderComponent,
	DashboardFormGeneratorComponent,
	DashboardViewerComponent
}                                             from './components';
import {
	AllDashboardPanelType
}                                             from './models/dashboard-models';
import {
	ApplicationSelectionTargetResult,
	ApplicationSelectionTargetResultMeta,
	ArrayUtils,
	ComponentChanges,
	DataDescribed, generateQuickGuid,
	hasPropertyOf,
	LoggerUtil, mergeDeep,
	PropertyAnnotation, restoreFlattenObject,
	SelectionTargetResult,
	ServerSidePaging, updateTargetSources,
	whenChanging
}                                             from '@cs/core';
import { UntilDestroy, untilDestroyed }       from '@ngneat/until-destroy';
import { DashboardEventHub }                  from './dashboard-event-hub.service';
import { SafeMethods, FormatProviderService } from '@cs/common';
import { isNullOrUndefined }                  from '@cs/core';
import { FilterCompareBarQuery }              from '@cs/components/filter-and-compare-bar';
import { DashboardComponentRegistry }         from '@cs/components/shared';
import { DomSanitizer }                       from '@angular/platform-browser';
import { ActivatedRoute }                     from '@angular/router';
import { addClass }                           from '@cs/components/util';

@UntilDestroy()
@Component({
												selector:        'cs-dashboard',
												templateUrl:     './dashboard.component.html',
												animations:      [collapse('isCollapsed')],
												changeDetection: ChangeDetectionStrategy.OnPush,
												providers:       [DashboardEventHub]
											})
export class DashboardComponent implements IDashboardComponent,
																																											OnInit,
																																											OnChanges,
																																											OnDestroy {

	/**
		* The object that is used to renders the dashboard layout and the panels
		*/
	@Input() data: DashboardGridData;
	/**
		* Set the context for the dashboard
		*/
	@Input() contextObject: {
		[key: string]: any
	};
	/**
		* Collapse the dashboard to 0 height
		*/
	@Input() isCollapsed: boolean;
	/**
		* When a element in one of the panels has been clicked, it could fire a event {@link DashboardEventHub.isDashboardEntryIsClicked}
		* so the dashboard parent could execute the requested action. {@link SelectionTargetResult.selectionAction}
		*/
	@Output() dashboardEntityClicked = new EventEmitter<SelectionTargetResult>();

	/**
		* Application trigger requested by the server
		*/
	@Output() applicationTriggerRequested = new EventEmitter<ApplicationSelectionTargetResult>();

	/**
		* A panel could have a download option. This will fire when a button in the header of the panel is clicked.
		*/
	@Output() downloadButtonClicked = new EventEmitter<DashboardDownloadButtonEventArgs>();

	/**
		* A panel could have multiple views that could be selected by the PanelViewOption
		*/
	@Output() panelOptionSelected = new EventEmitter<DashboardPanelSettingEventArgs>();

	/**
		* A panel has a the option to request a Modal window with extra information {@link DashboardPopupComponent}
		*/
	@Output() showDetailsButtonClicked = new EventEmitter<SelectionTargetResult>();

	/**
		* A panel would like to send changes made by the user to the server
		*/
	@Output() notifyChangesToServer = new EventEmitter<NotifyServerForChangesDashboardPanelEventArgs<any>>();

	@Output() newDashboardRendered = new EventEmitter<{
		firstRender: boolean
	}>();

	dashboardGrid: DashboardGridLayout;
	readonly dashboardInstanceId = generateQuickGuid();


	constructor(@Inject(forwardRef(() => DashboardEventHub)) private dashboardEventHub: DashboardEventHub,
													@Optional() @Inject(forwardRef(() => DashboardComponentRegistry)) private registry: DashboardComponentRegistry,
													private filterCompareBarQuery: FilterCompareBarQuery,
													private changeRef: ChangeDetectorRef,
													private route: ActivatedRoute,
													private sanitizer: DomSanitizer,
													private formatService: FormatProviderService) {
		this.dashboardEventHub.registerAsParent(this);
	}

	trackById = (index: number, item: DashboardGridColumn) => item.id;

	ngOnInit() {

		this.dashboardEventHub.isDashboardEntryIsClicked
						.pipe(untilDestroyed(this))
						.subscribe(value => {
							value.dashboardInstanceId = this.dashboardInstanceId;

							this.dashboardEntityClicked.emit(value);
						});

		this.dashboardEventHub.onNotifyChangesToServer
						.pipe(untilDestroyed(this))
						.subscribe(value => {
							this.notifyChangesToServer.emit(value);
						});
	}

	getPanel(name: string): IDashboardPanel {
		return this.dashboardGrid.getPanel(name);
	}

	ngOnDestroy() {
	}

	detectChanges() {
		SafeMethods.detectChanges(this.changeRef);
	}

	updatePanelWithDisplayHints(panelToPatch: IDashboardPanel) {

		if (!hasPropertyOf(panelToPatch, 'meta') || !hasPropertyOf(panelToPatch.meta, 'displayHints'))
			return;

		if (panelToPatch.meta.displayHints.indexOf(DashboardPanelMetaInfoEnum.LittleLarger) > -1)
			panelToPatch.class += ' little-larger ';

	}

	ngOnChanges(changes: ComponentChanges<DashboardComponent>): void {

		whenChanging(changes.data, true)
			.execute(value => {

				const isDifferent = this.shouldParseData(value.currentValue, value.previousValue);

				if (isDifferent)
					this.newDashboardRendered.emit({firstRender: this.dashboardGrid == null});
				// When patching it updates the current dashboard data instance, this will allow for smoother chart changes
				// When parsing the dashboard will be teared down and build up completely. Has a blurred transition
				const executeMethod = isDifferent
																										? this.parseDashboardGridData(value.currentValue)
																										: this.patchDashboardGridData(value.currentValue);

				executeMethod.then(grid => {
					this.dashboardGrid = grid;
					SafeMethods.detectChanges(this.changeRef);
				});
			});
	}

	metaButtonClicked(icon: DashboardPanelInfoIcon, panel: IDashboardPanel) {
		const urlParams           = this.getPanelSettingsFromUrl();
		const mainbarResultParams = this.filterCompareBarQuery.getValue().mainbarResultParams;

		const eventArgs = {
			panelName: panel.name,
			triggerId: icon.name,
			// this should be removed in the future in favor of the selectionContext
			selectionObject:  Object.assign({}, mainbarResultParams,
																																			{panelSettings: urlParams}) as unknown as {
				[key: string]: string | number
			},
			selectionContext: Object.assign({}, mainbarResultParams,
																																			{panelSettings: urlParams}) as unknown as {
				[key: string]: string | number
			}
		};

		if (!isNullOrUndefined(icon.action)) {
			switch (icon.action.toLowerCase()) {
				case 'download':
					// combine selection meta info from the button with the navbar selection
					Object.assign(eventArgs.selectionObject, icon.selectionMeta);

					this.downloadButtonClicked.emit({
																																						...eventArgs,
																																						icon: icon
																																					} as DashboardDownloadButtonEventArgs);
					break;
				case 'details':
					this.showDetailsButtonClicked.emit({
																																									...eventArgs,
																																									selectionRoute:      null,
																																									selectionAction:     'ModalWindow',
																																									dashboardInstanceId: this.dashboardInstanceId
																																								});
					break;
				case 'ipa':
					this.dashboardEntityClicked.emit({
																																							...eventArgs,
																																							...updateTargetSources({
																																																															row:    icon.selectionMeta,
																																																															column: icon as unknown as PropertyAnnotation<any>
																																																														}, {
																																																															dataAnnotation: {
																																																																displayName: '',
																																																																fields:      [icon as unknown as PropertyAnnotation<any>],
																																																																groups:      []
																																																															}
																																																														} as any, panel.name),
																																							dashboardInstanceId: this.dashboardInstanceId
																																						});
					break;
				case 'pa':
					this.dashboardEventHub.notifyMessageBus({
																																														...eventArgs,
																																														selectionRoute:      icon.selectionRoute,
																																														selectionAction:     'PanelAction',
																																														dashboardInstanceId: this.dashboardInstanceId
																																													});
					break;

				case 'application':
					this.applicationTriggerRequested.emit({
																																												...eventArgs,
																																												selectionRoute:      null,
																																												selectionAction:     'Application',
																																												dashboardInstanceId: this.dashboardInstanceId,
																																												selectionMeta:       icon.selectionMeta as ApplicationSelectionTargetResultMeta
																																											});
					break;
				default:
					LoggerUtil.error(`The action ${icon.action} is not found`);
					break;
			}
		}
	}

	setIconLoader(icon: DashboardPanelInfoIcon) {
		return setTimeout(() => {
			icon.loading = 'loader';
			this.detectChanges();
		}, 300);
	}

	buildNoteHTML(panel: IDashboardPanel) {
		if (!isNullOrUndefined(panel.note)) {
			panel.note = this.sanitizer.bypassSecurityTrustHtml(panel.note as string);
			if (panel.type.toLowerCase() === DashboardPanelType.CHART)
				panel.class += ' chart-note';
		} else {
			panel.note = null;
		}
	}


	public getPanelSettingsFromUrl(clearValues = false): {
		[key: string]: any
	} {

		const params   = this.route.snapshot.queryParams || {};
		const restored = restoreFlattenObject(params, clearValues);

		if (restored.hasOwnProperty('panelSettings') && restored.panelSettings != null) {
			return restored.panelSettings;
		}

		return {};
	}

	panelOptionClicked(value: any, panel: IDashboardPanel, field: PropertyAnnotation<unknown>) {
		const params  = this.filterCompareBarQuery.getValue().mainbarResultParams as unknown as {
			[key: string]: string | number
		};
		const options = panel.options as DataDescribed<{
			[key: string]: string
		}>;

		options.data[field.id] = value;

		ServerSidePaging.verifyPageIndex(options as unknown as DataDescribed<ServerSidePaging>);

		const panelSettings = {
			...panel.options.data as any
		} as unknown as PanelSettings;

		const urlParams = this.getPanelSettingsFromUrl();

		const selectedSettings = {
			[panel.name]: panelSettings
		};

		this.panelOptionSelected.emit({
																																	panelName:           panel.name,
																																	selectionObject:     params,
																																	selectionAction:     'CurrentWindow',
																																	selectionRoute:      null,
																																	dashboardInstanceId: this.dashboardInstanceId,
																																	selectionContext:    params,
																																	panelSettings:       Object.assign({},
																																																																				urlParams, selectedSettings)
																																}
		);
	}


	getPanelSettings(data: DashboardGridData): {
		[key: string]: any
	} {
		return mergeDeep(this.getPanelSettingsFromUrl(true), this.getPanelSettingsFromData(data));
	}

	getPanelComponent(name: string) {
		return this.dashboardEventHub.registeredHostPanelComponents.find(value => value.panel.name === name);
	}

	public getPanelSettingsFromData(data: DashboardGridData = null, clearValues = false): {
		[key: string]: any
	} {
		if (data == null)
			return {};

		return data.panels.reduce((previousValue, currentValue) => {
			if (currentValue.options && currentValue.options.data)
				previousValue[currentValue.name] = clearValues
																																							? null
																																							: currentValue.options.data;

			return previousValue;
		}, {});
	}

	panelIsLoading(isLoading: boolean, panelName: string) {
		this.dashboardEventHub.panelIsLoading(isLoading, panelName);
	}

	/**
		* Returns the row that contains the panel
		* @param name the panel name that should be in the row
		*/
	getRow(panelName: string) {
		return this.dashboardGrid.getRow(panelName);
	}


	private async parseDashboardGridData(newData: DashboardGridData) {
		this.dashboardEventHub.resetRegisteredPanels();

		const gridData = new DashboardGridData(newData);

		const gridDataLayout = new DashboardGridLayout();
		let colIndex         = 0;
		for (const row of gridData.grid) {
			const rowLayout = new DashboardGridRow();

			for (const col of row) {
				const colLayout   = new DashboardGridColumn();
				colLayout.colspan = col;
				colLayout.panels  = newData.panels
																															.filter(p => p && p.gridSlot === colIndex)
																															.map(value => {
																																if (value.options != null) {
																																	value.options = new DataDescribed(value.options);
																																}
																																return new DashboardPanel(value);
																															});
				colLayout.panels.forEach(p => {
					p.component = this.getComponentType(p);
					this.updatePanelWithDisplayHints(p);
					this.buildNoteHTML(p);
				});

				// Search for a render Orientation. We take the first element out of convenience. And see if there is a value.
				// This will render TABS or STACKED in one Colslot
				if (colLayout.panels && colLayout.panels.length > 0 && colLayout.panels[0].renderOrientation)
					colLayout.renderOrientation = colLayout.panels[0].renderOrientation.toUpperCase() as RenderOrientation;

				rowLayout.columns.push(colLayout);
				colIndex++;
			}

			gridDataLayout.grid.push(rowLayout);
		}
		gridDataLayout.alerts = gridData.alerts;
		return gridDataLayout;
	}

	private async patchDashboardGridData(newData: DashboardGridData) {

		const gridData = newData;

		const gridDataLayout = this.dashboardGrid;

		for (const item of gridData.panels.filter(value => value !== null)) {

			const panel: AllDashboardPanelType = item;

			const allPanels = gridDataLayout.grid.reduce((previousValue, currentValue) => {
				previousValue.push(...currentValue.columns.reduce((previousValue1, currentValue1) => {
					previousValue1.push(...currentValue1.panels);
					return previousValue1;
				}, [] as AllDashboardPanelType[]));
				return previousValue;
			}, [] as AllDashboardPanelType[]);

			// Check if panel is null and handle it, but trigger a error for the error logger
			if (isNullOrUndefined(panel)) {
				const indexOfNull = [];
				gridData.panels.filter((value, index) => {
					if (value === null) {
						indexOfNull.push(index);
						return true;
					}
				});
				LoggerUtil.error(`Panels at indexes [${indexOfNull.join(', ')}] are NULL`, true);
				continue;
			}

			const panelComponentToPatch = this.dashboardEventHub.registeredPanelComponents.find(value => value.name === panel.name);

			const panelToPatch = allPanels.find(value => value.name === panel.name);

			panelToPatch.meta = panel.meta;

			if (panelToPatch.type !== panel.type) {
				const foundIndex = this.dashboardEventHub.registeredPanelComponents.findIndex(value => value.name === panel.name);

				if (foundIndex === -1) {
					LoggerUtil.error(`${panel.name} is not found`);
					continue;
				}

				this.dashboardEventHub.registeredPanelComponents.splice(foundIndex, 1);

				panelToPatch.type      = panel.type;
				panelToPatch.class     = panel.class;
				panelToPatch.label     = panel.label;
				panelToPatch.component = this.getComponentType(panelToPatch);
				panelToPatch.data      = panel.data;
				panelToPatch.options   = panel.options != null
																													? new DataDescribed(panel.options)
																													: null;
				panelToPatch.reason    = panel.reason;
				panelToPatch.infoType  = panel.infoType;
				panelToPatch.note      = panel.note;
				this.updatePanelWithDisplayHints(panelToPatch);

				continue;
			}

			if (panelComponentToPatch && panelComponentToPatch.update) {
				panelToPatch.label    = panel.label;
				panelToPatch.note     = panel.note;
				panelToPatch.options  = panel.options != null
																												? new DataDescribed(panel.options)
																												: null;
				panelToPatch.infoType = panel.infoType;
				panelToPatch.reason   = panel.reason;
				panelToPatch.label    = panel.label;

				// Resetting the styling
				this.getComponentType(panelToPatch);
				panelToPatch.class = addClass(panel.class, panelToPatch.class);

				this.buildNoteHTML(panelToPatch);
				panelComponentToPatch.update(panel.data);
			} else {
				panelToPatch.data = panel.data;
			}

			this.updatePanelWithDisplayHints(panelToPatch);

			console.log(panelComponentToPatch);
		}

		gridDataLayout.alerts = gridData.alerts;
		return gridDataLayout;
	}

	private getComponentType(panel: AllDashboardPanelType) {
		const type = panel.type.toLowerCase();
		switch (type) {
			case DashboardPanelType.LINKS:
				panel.class = 'quick_links';
				return HyperlinkListComponent;
			case DashboardPanelType.TABLE:
				panel.class = 'data_entry';
				return DashboardTableComponent;
			case DashboardPanelType.CHART_LEGACY:
				panel.class = 'chart_panel';
				return DashboardChartComponent;
			case DashboardPanelType.CHART:
				panel.class = 'chart_panel';
				return DashboardChartNxtComponent;
			case DashboardPanelType.CHART_SLIDER:
				panel.class = 'chart_slider_panel chart_panel';
				return DashboardChartNxtSliderComponent;
			case DashboardPanelType.HTML:
				panel.class = 'html_panel';
				return DashboardHtmlComponent;
			case DashboardPanelType.STATS:
				panel.class = 'stats_panel';
				return DashboardStatisticsComponent;
			case DashboardPanelType.COMBI_ENTRY_STATE:
				panel.class = 'combi_entry_state_panel';
				return DashboardCombiEntryStateComponent;
			case DashboardPanelType.GAUGE:
				panel.class = 'gauge_panel';
				return DashboardGaugeComponent;
			case DashboardPanelType.INDICATORS:
				panel.class = 'indicators_panel';
				return DashboardIndicatorsComponent;
			case  DashboardPanelType.SINGLE_INDICATORS:
				panel.class = 'single-indicators_panel';
				return DashboardSingleIndicatorsComponent;
			case DashboardPanelType.LIST_GROUPED_ITEMS:
				panel.class = 'list_grouped_items';
				return DashboardListGroupedItemsComponent;
			case DashboardPanelType.TABLE_NXT:
				panel.class = 'table_nxt_panel';
				return DashboardGenericTableComponent;
			case DashboardPanelType.EMPTY:
				panel.class = 'empty_panel';
				panel.class = addClass(panel.class, panel.infoType);
				return DashboardEmptyComponent;
			case DashboardPanelType.INFORMATION:
				panel.class = 'information_panel';
				panel.class = addClass(panel.class, panel.infoType);
				// panel.hasShadow = false;
				return DashboardInformationComponent;
			case DashboardPanelType.TASKS:
				panel.class = 'tasks_panel';
				return DashboardTasksComponent;
			case DashboardPanelType.UPDATES:
				panel.class = 'updates_panel';
				return DashboardUpdatesComponent;
			case DashboardPanelType.FORM_GENERATOR:
				panel.class = 'form_panel';
				return DashboardFormGeneratorComponent;
			case DashboardPanelType.VIEWER:
				panel.class = 'viewer_panel';
				return DashboardViewerComponent;
			default: {

				if (this.registry && this.registry.hasTemplate(type)) {
					panel.class = `${type}_panel`;
					return this.registry.getTemplate(type);
				}

				panel.class = 'html_panel';
				panel.data  = {html: 'NOT_IMPLEMENTED_PANEL'};
				console.error(`Panel type: '${panel.type}' is not found`);
				return DashboardHtmlComponent;
			}
		}
	}


	/**
		* Method to check if the dasboard data is the similar to previous dashboard.
		* @param currentValue New dashboard data to render
		* @param previousValue Current dashboard data already rendered
		* @return result if previous data is to different than the current data for patching
		*/
	private shouldParseData(currentValue: DashboardGridData, previousValue: DashboardGridData): boolean {
		return isNullOrUndefined(previousValue)
			|| currentValue.name !== previousValue.name
			|| !(ArrayUtils.isEqual(currentValue.grid, previousValue.grid)
								?
								(currentValue.panels.length === previousValue.panels.length
									&& this.panelsHaveSameName(currentValue, previousValue))
								: false);
	}

	/**
		* Check if the panels in both collections have the same name
		* @param currentValue the new dashboard data
		* @param previousValue the current rendered dashboard data
		*/
	private panelsHaveSameName(currentValue: DashboardGridData, previousValue: DashboardGridData) {
		const currentPanelNames  = currentValue.panels.map(value => value.name);
		const previousPanelNames = previousValue.panels.map(value => value.name);

		return ArrayUtils.isEqual(currentPanelNames, previousPanelNames);
	}

}
