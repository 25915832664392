<span class="cs-switch-container">
  <span
			class="cs-switch cs-{{ size }}"
			[class.cs-checked]="checked"
			[class.cs-disabled]="disabled"
		>
    <small></small>
  </span>
  <span *ngIf="label !== undefined" class="cs-switch-label">
    {{ label }}
  </span>
</span>
