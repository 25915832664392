import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CsSwitchComponent } from './switch.component';

@NgModule({
    declarations: [CsSwitchComponent],
    exports: [CsSwitchComponent],
    imports: [CommonModule],
    entryComponents: [CsSwitchComponent]
})
export class CsSwitchModule {
    static forRoot(): ModuleWithProviders<CsSwitchModule> { return { ngModule: CsSwitchModule }; }
}
