import { forwardRef, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CsSwitchModule }                                  from '@cs/components/switch';

import { CsTableNxtComponent } from './cs-table-nxt.component';
import { CommonModule }        from '@angular/common';

import { CsSharedModule, CsTemplateRegistry }                                     from '@cs/components/shared';
import { ComponentTranslationLoaderService, FormatProviderService, KebabifyPipe } from '@cs/common';
import { FormatRegistry }                                                         from '@cs/common';
import { FormsModule, ReactiveFormsModule }                                       from '@angular/forms';
import { CsTableNxtActions }                                                      from './cs-table-nxt-action.directive';
import { DefaultFormatRegistry }                                                  from '@cs/common';
import { MatSelectSearchModule }                                                  from '@cs/components/mat-select-search';
import { MatSelectModule }                                                        from '@angular/material/select';
import { MatTooltipModule }                                                       from '@angular/material/tooltip';
import { ScrollingModule }                                                        from '@angular/cdk/scrolling';
import { TranslateModule }                                                        from '@ngx-translate/core';
import { TableMenuIconPipe }                                                      from './table-menu/table-menu-icon.pipe';
import { NgbCollapseModule }                                                      from '@cs/components/ngb-collapse';
import { CsDatepickerModule }                                                     from '@cs/components/datepicker';

const DIRECTIVES = [CsTableNxtActions, TableMenuIconPipe, KebabifyPipe];
const COMPONENTS = [CsTableNxtComponent];

@NgModule({
						imports:         [
							CommonModule,
							CsSharedModule,
							FormsModule,
							ReactiveFormsModule,
							MatSelectModule,
							MatTooltipModule,
							MatSelectSearchModule,
							NgbCollapseModule,
							ScrollingModule, TranslateModule, CsDatepickerModule, CsSwitchModule
						],
						exports:         [
							...COMPONENTS,
							...DIRECTIVES
						],
						declarations:    [...COMPONENTS, ...DIRECTIVES],
						providers:       [],
						entryComponents: [...COMPONENTS]
					})
export class CsTableNxtModule {
	static forChild(): ModuleWithProviders<CsTableNxtModule> {
		return {
			ngModule: CsTableNxtModule, providers: [
				{
					provide:  CsTemplateRegistry,
					useClass: CsTemplateRegistry
				},
				{
					provide:  FormatProviderService,
					useClass: forwardRef(() => FormatProviderService)
				}
			]
		};
	}

	constructor(loader: ComponentTranslationLoaderService) {
		loader.registerComponentModule('table-nxt');
	}
}
