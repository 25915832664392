import { TitleCasePipe }                                      from '@angular/common';
import { isNullOrUndefined, isString }                        from '@cs/core/utils';
import * as Sentry                                            from '@sentry/browser';
import { CsLoggerOptions }                                    from '../classes/cs-logger-options';
import { Environment }                                        from '../classes/cs-enviroment';
import { ILoggerBreadcrumb, ILoggerProvider, LoggerResponse } from '../classes/logger-provider.interface';
import { Severity, Event }                                    from '@sentry/browser';
import { Result }                                             from '@cs/core';
import { LoggerUtil }                                         from '@cs/core';


export class CsRavenOptions extends CsLoggerOptions {
	dns: string;
	options: {
		[key: string]: string
	};

	constructor(init: {
		dns: string,
		options: {
			[key: string]: string
		},
		version: string,
		environment: Environment,
		enableErrorLogging?: boolean,
		rateLimitCount?: number,
		rateLimitPeriod?: number
	}) {
		super(init.version, init.environment);

		const {dns, options} = init;
		this.dns             = dns;
		this.options         = options;
	}

}

export class SentryLogger implements ILoggerProvider<CsRavenOptions> {
	options: CsRavenOptions;

	constructor(options: CsRavenOptions) {
		this.options = options;
	}

	captureException(e: Error): Promise<Result<LoggerResponse>> {
		return new Promise<Result<LoggerResponse>>(resolve => {
			const result = Sentry.captureException(e);
			LoggerUtil.error(result);
			const response       = new LoggerResponse();
			response.referenceId = result;
			resolve(Result.success(response));
		});
	}

	setupErrorProvider(): Promise<boolean> {

		const serverErrorsRegex = new RegExp(
			'500 Internal Server Error|401 Unauthorized|Http failure.+401|Http failure.+403|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable|rest\\/app\\/keep-alive',
			'mi'
		);

		Sentry.init({
															dsn: this.options.dns,
															// set application version
															release:     this.options.version,
															environment: new TitleCasePipe().transform(this.options.environment),
															beforeSend(event, hint) {
																// We ignore Server Errors. We have to define here since Angular
																// http client uses setTimeout to detect http call progress.
																// And when the call fails, it throws an exception inside that timeout
																// that bubbles up higher to the main Angular's error handler.

																const errorMessage = isString(hint.originalException)
																																					? hint.originalException
																																					: hint.originalException.message || event.message || '';

																if (new RegExp('Unknown Error').test(errorMessage)) {
																	event.tags = {
																		...event.tags, // Preserve existing tags
																		httpErrorCode: '0'
																	};
																}

																if (serverErrorsRegex.test(errorMessage)) {
																	// Drop the event
																	return null;
																}

																// Send the event
																return event;
															}
														});

		return Promise.resolve(true);
	}

	addBreadcrumb(param: ILoggerBreadcrumb): void {

		Sentry.addBreadcrumb({
																								data:      param.data,
																								category:  param.category,
																								type:      param.type,
																								timestamp: Date.now(),
																								level:     Severity.Log,
																								message:   param.message
																							});

	}

}
